<template lang="pug">
  div
    v-row
      v-col(cols="3")
        create-revenue-source(
          :default-value.sync="revenueSource"
        )
      v-col(cols="9")
        table-revenue-source(
          v-model="revenueSource"
        )
</template>
<script>
export default {
  name: 'RevenueSources',
  components: {
    createRevenueSource: () => import('./Create'),
    tableRevenueSource: () => import('./Table'),
  },
  data () {
    return {
      revenueSource: {},
    }
  },
}
</script>